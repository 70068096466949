import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '../_directives/alert.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SafePipe } from './pipes/safe.pipe';
import { EwPaginationComponent } from './ew-pagination/ew-pagination.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FormsModule } from '@angular/forms';
import { NoSpecialChar1Directive } from './directives/no-special-char1.directive';
import { EwFileUploadComponent } from './components/ew-file-upload/ew-file-upload.component';
import { FilterPipe } from './pipes/filter.pipe';
import { EwSelectComponent } from './components/ew-select/ew-select.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { EwMultiSelectComponent } from './components/ew-multi-select/ew-multi-select.component';
import { OnlyNumber } from './directives/only-number';
import { LabelPipe } from './pipes/label.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EwImgCropperComponent } from './components/ew-img-cropper/ew-img-cropper.component';
import { EwMultiSelect2Component } from './components/ew-multi-select2/ew-multi-select2.component';
import { EwEditorComponent } from './components/ew-editor/ew-editor.component';
import { EwDatepickerComponent } from './components/ew-datepicker/ew-datepicker.component';
import { EwDatepicker1Component } from './components/ew-datepicker1/ew-datepicker1.component';
import { MultiFilterPipe } from './pipes/multi-filter.pipe';
import { ClickOutsideDirective } from './directives/click-outside';
import { TextAreaAutoResizeDirective } from './directives/textarea-autoresize';
import { EwFileUpload2Component } from './components/ew-file-upload2/ew-file-upload2.component';
import { EwSelectMiniComponent } from './components/ew-select-mini/ew-select-mini.component';
import { EwSliderComponent } from './components/ew-slider/ew-slider.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EWURL } from './pipes/ew-url.pipe';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { TitleSymbolCasePipe } from './pipes/title-symbol-case.pipe';
import { EwSelectComponentTemplate } from './components/ew-select-template/ew-select-template.component';
import { UserInsightsComponent } from '../user-insights/user-insights.component';
import { UserInsightDetailsComponent } from '../user-insights/user-insight-details/user-insight-details.component';
import { UserKnowledgeSeriesComponent } from '../user-knowledge-series/user-knowledge-series.component';
import { UserKnowledgeSeriesDetailsComponent } from '../user-knowledge-series/user-knowledge-series-details/user-knowledge-series-details.component';
import { RouterModule } from '@angular/router';
import { TruncateContentDirective } from './directives/truncate-content.directive';
import { RelativeDatePipe } from './pipes/relative-date';
import { EwEditor1Component } from './components/ew-editor1/ew-editor1.component';
import { EwTimepickerComponent } from './components/ew-timepicker/ew-timepicker.component';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';
import { SupportRequestsComponent } from './components/support-requests/support-requests.component';
import { OrgUsersListComponent } from './components/org-users/org-users-list/org-users-list.component';
import { AddEditUserFormComponent } from './components/org-users/add-edit-user-form/add-edit-user-form.component';
import { ChecklistFormComponent } from '../oem/solutions/checklist/checklist-form/checklist-form.component';

@NgModule({
  declarations: [    
    AlertComponent,
    ConfirmDialogComponent,
    SafePipe,
    SafeHTMLPipe,
    EwPaginationComponent,
    UserProfileComponent,
    NoSpecialChar1Directive,
    EwFileUploadComponent,
    FilterPipe,
    MultiFilterPipe,
    EWURL,
    EwSelectComponent,
    AutocompleteInputComponent,
    EwMultiSelectComponent,
    EwMultiSelect2Component,
    EwSelectMiniComponent,
    OnlyNumber,
    LabelPipe,
    EwImgCropperComponent,
    EwEditorComponent,
    EwDatepickerComponent,
    EwDatepicker1Component,
    ClickOutsideDirective,
    TextAreaAutoResizeDirective,
    EwFileUpload2Component,
    EwSliderComponent,
    ImageViewerComponent,
    TitleSymbolCasePipe,
    EwSelectComponentTemplate,
    UserInsightsComponent,
    UserInsightDetailsComponent,
    UserKnowledgeSeriesComponent,
    UserKnowledgeSeriesDetailsComponent,
    TruncateContentDirective,
    RelativeDatePipe,
    EwEditor1Component,
    EwTimepickerComponent,
    ChatPanelComponent,
    SupportRequestsComponent,
    OrgUsersListComponent,
    AddEditUserFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    ImageCropperModule,
    RouterModule
  ],
  exports: [
    AlertComponent,
    SafePipe,
    SafeHTMLPipe,
    EwPaginationComponent,
    UserProfileComponent,
    NoSpecialChar1Directive,
    EwFileUploadComponent,
    FilterPipe,
    MultiFilterPipe,
    EWURL,
    EwSelectComponent,
    AutocompleteInputComponent,
    EwMultiSelectComponent,
    EwMultiSelect2Component,
    EwSelectMiniComponent,
    OnlyNumber,
    LabelPipe,
    EwImgCropperComponent,
    EwEditorComponent,
    EwDatepickerComponent,
    EwDatepicker1Component,
    ClickOutsideDirective,
    TextAreaAutoResizeDirective,
    EwFileUpload2Component,
    EwSliderComponent,
    TitleSymbolCasePipe,
    EwSelectComponentTemplate,
    UserInsightsComponent,
    UserInsightDetailsComponent,
    UserKnowledgeSeriesComponent,
    UserKnowledgeSeriesDetailsComponent,
    TruncateContentDirective,
    RelativeDatePipe,
    EwEditor1Component,
    EwTimepickerComponent,
    ChatPanelComponent,
    SupportRequestsComponent,
    OrgUsersListComponent,
    AddEditUserFormComponent
  ],
  entryComponents: [EwImgCropperComponent, ImageViewerComponent, ChecklistFormComponent]
})
export class SharedModule { }
