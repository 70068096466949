import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { OemService } from 'src/app/oem/oem.service';
import { AlertService } from 'src/app/service/alert.service';
import { Business } from 'src/app/service/Business.service';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ModalHideContent } from '../models';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Feature, Permission } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-checklist-form',
  templateUrl: './checklist-form.component.html',
  styleUrls: ['./checklist-form.component.scss']
})
export class ChecklistFormComponent implements OnInit {
  type = '';
  checklist_category_name = '';
  checklist_id = null;
  solution_id = null;
  installation_id = null;
  installed_equipment_id = null;
  oem_org = null;
  equipment_name = '';

  is_view = false;
  is_clone = false;
  view_checklist_index = null;
  show_new_task_form = false;
  new_checklist = {
    id: null, solution: null, installation: null, supplier_equipment_id: null, checklist_type: null, name: '', description: '',
    equipment_rating: '', remarks: '', oem_organization: null, fields: [], delete_field_ids: [], checklist_fields: [],
    installed_equipment_id: null,
    checklist_type_name: '',
    ferror: { checklist_type: false, name: false, description: false, fields: false, dup_name: false }
  };
  checklist_types = [{ id: 1, name: 'Daily' }, { id: 2, name: 'Weekly' }, { id: 3, name: 'Monthly' }, { id: 4, name: 'Quarterly' },
  { id: 5, name: 'Half-Yearly' }, { id: 6, name: 'Annual' }];
  new_field = {
    id: null,
    oem_instructions: '', oem_file: '', oem_file_ext: '', field_name: '', input_type: '1', field_description: '', uom: '',
    complete_status: null, fm_notes: '', fm_file: '', fm_notes_required: false, fm_file_required: false, list_options: '',
    reference_files: [], documents: [], capacity_uom: null,
    ferror: { field_name: false, input_type: false, field_description: false, uom: false, list_options: false, dup_name: false }
  };
  input_types = [{ id: 1, name: 'Text' }, { id: 2, name: 'Number' }, { id: 3, name: 'Dropdown' }, { id: 4, name: 'Date' },
  { id: 5, name: 'Yes or No' }];
  field_update = false;

  capacity_uoms = [];

  mobile_view = false;
  is_my_win_user = false;
  is_reseller = false;
  modalHideContent: ModalHideContent = { action: 'close', save_index: null, delete_index: null, checklist: null, is_created: false };
  mode = '';
  dynamic_data_perm: Permission = null;

  constructor(
    private confirmDialog: ConfirmDialogService,
    private oemService: OemService,
    private alertService: AlertService,
    private businessService: Business,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.initPermissions();
    this.getCapacityUOMs();
    this.route.queryParams.subscribe((params) => {
      this.checklist_id = params['checklist_id'];
      this.solution_id = params['solution_id'];
      this.installation_id = params['installation_id'];
      this.installed_equipment_id = params['installed_equipment_id'];
      this.is_clone = params['is_clone'] === 'true';
      this.mode = params['mode'];
      this.type = params['type'];
      if (this.type) {
        this.checklist_category_name = this.type == 'mnt' ? 'Maintenance' : 'Health';
        if (this.solution_id || this.installed_equipment_id) {
          if (this.checklist_id) {
            this.getChecklistDetails();
          } else {
            this.is_view = false;
          }
        }
      }
    });
  }

  async initPermissions() {
    const current_user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.is_my_win_user = current_user?.user_type == 'My Win User' ? true : false;
    this.is_reseller = current_user?.user_type == 'Reseller' ? true : false;

    let oem_permissions = await this.authService.getUserPermissions();
    this.dynamic_data_perm = oem_permissions.find(x => x.feature_name == Feature.DynamicDataChecklistSpareList);
  }

  getCapacityUOMs() {
    this.oemService.getCapacityUOMs().subscribe((data) => {
      this.capacity_uoms = data;
    });
  }

  getChecklistDetails() {
    this.is_view = this.mode == 'edit' ? false : true;
    let getChecklistDetails$
    if (this.type == 'mnt') {
      getChecklistDetails$ = this.oemService.getSolutionMntChecklistDetails(this.checklist_id);
    }
    if (this.type == 'health') {
      getChecklistDetails$ = this.oemService.getSolutionHealthChecklistDetails(this.checklist_id);
    }
    if (this.type == 'install_mnt') {
      getChecklistDetails$ = this.oemService.getInstallationChecklistById(this.checklist_id);
    }
    if (this.type == 'install_health') {
      getChecklistDetails$ = this.oemService.getInstallationHealthChecklistById(this.checklist_id);
    }

    if (getChecklistDetails$) {
      getChecklistDetails$.subscribe((resp) => {
        if (!this.is_clone) {
          this.new_checklist.id = resp.id;
          this.new_checklist.name = resp.name;
        } else {
          this.is_view = false;
        }
        this.new_checklist.description = resp.description;
        this.new_checklist.checklist_type = resp.checklist_type;
        this.equipment_name = resp?.equipment_name;
        this.oem_org = resp?.oem_organization;
        this.new_checklist.fields = [];
        resp.checklist_fields.forEach(x => {
          const obj = {
            id: x.id,
            oem_instructions: x.oem_instructions, oem_file: x.oem_file, field_name: x.field_name, input_type: x.input_type,
            field_description: x.field_description, uom: x.uom, complete_status: null, fm_notes: '', fm_file: '',
            fm_notes_required: x.fm_notes_required, fm_file_required: x.fm_file_required, list_options: x.list_options,
            oem_file_url: x.oem_file_url, reference_files: x.reference_files, documents: x.documents, capacity_uom: x.capacity_uom,
            ferror: { field_name: false, input_type: false, field_description: false, uom: false, list_options: false, dup_name: false }
          };
          this.new_checklist.fields.push(obj);
        });
      });
    }
  }

  onEditChecklist() {
    this.is_view = false;
  }

  onAddNewChecklistTask() {
    this.show_new_task_form = true;
  }

  viewChecklistField(field) {
    this.new_field = field;
    this.show_new_task_form = true;
    this.field_update = true;
  }

  setNewField() {
    this.new_field = {
      id: null,
      oem_instructions: '', oem_file: '', oem_file_ext: '', field_name: '', input_type: '1', field_description: '', uom: '',
      complete_status: null, fm_notes: '', fm_file: '', fm_notes_required: false, fm_file_required: false, list_options: '',
      reference_files: [], documents: [], capacity_uom: null,
      ferror: {
        field_name: false, input_type: false, field_description: false, uom: false, list_options: false, dup_name: false
      }
    };
  }

  removeField(field, i, event: Event) {
    event.stopPropagation();
    this.confirmDialog.showConfirm(`Are you sure you want to delete field "${field.field_name}" ?`)
      .pipe(first()).subscribe((status) => {
        if (status) {
          this.new_checklist.delete_field_ids.push(field.id);
          this.new_checklist.fields = [...this.new_checklist.fields.filter(x => x.field_name != field.field_name)];
        }
      });
  }

  onCloseChecklistDetails(is_created = false) {
    if (is_created) {
      if (this.solution_id) {
        this.router.navigate(['/oem/equipment-solution'], { queryParams: { id: this.solution_id } });
        return;
      }
      if (this.installation_id) {
        this.router.navigate(['/oem/equipment-installation'], { queryParams: { id: this.installation_id } });
        return;
      }
    }
    this.location.back();

    // this.bsModalRef.content = this.modalHideContent;
    // (document.getElementsByTagName("BODY")[0] as any).style.overflow = "visible";
    // this.checklist_form_width = 0;    
    // this.show_new_task_form = false;
    // this.view_checklist_index = null;
    // this.is_view_checklist = false;
    // this.new_checklist = {
    //   id: null, solution: null, installation: null, supplier_equipment_id: null, checklist_type: null, name: '', description: '',
    //   equipment_rating: '', remarks: '', oem_organization: null, fields: [], delete_field_ids: [], checklist_fields: [],
    //   checklist_type_name: '',
    //   ferror: { checklist_type: false, name: false, description: false, fields: false, dup_name: false }
    // };
    // this.setNewField();
  }

  onSaveNewChecklistTask() {
    this.new_field.field_name = this.new_field.field_description;
    const is_valid = this.validateChecklistField();
    if (!is_valid) {
      this.alertService.error('Please fill all required fields');
      return;
    }
    if (!this.field_update) {
      this.new_checklist.fields.push({ ...this.new_field });
    }
    this.setNewField();
    this.show_new_task_form = false;
    this.field_update = false;
  }

  onCancelChecklistTask() {
    this.setNewField();
    this.show_new_task_form = false;
    this.field_update = false;
  }

  validateChecklist(): boolean {
    const _dup_name = this.new_checklist.ferror.dup_name;
    this.new_checklist.ferror = { checklist_type: false, name: false, description: false, fields: false, dup_name: _dup_name };
    this.new_checklist.fields.forEach(x => {
      x.ferror = { field_name: false, input_type: false, field_description: false, uom: false, list_options: false, dup_name: false }
    });

    if (!this.new_checklist.checklist_type) {
      this.new_checklist.ferror.checklist_type = true;
    }
    if (!this.new_checklist.name) {
      this.new_checklist.ferror.name = true;
    }
    if (!this.new_checklist.description) {
      this.new_checklist.ferror.description = true;
    }

    const eKeys = Object.keys(this.new_checklist.ferror);
    const isValid = eKeys.every((ek) => this.new_checklist.ferror[ek] == false);
    return isValid;

  }

  validateChecklistField() {
    this.new_field.ferror = {
      field_name: false, input_type: false, field_description: false, uom: false,
      list_options: false, dup_name: false
    }

    if (!this.new_field.input_type) {
      this.new_field.ferror.input_type = true;
    }
    if (!this.new_field.field_description) {
      this.new_field.ferror.field_description = true;
    }
    if (this.new_field.input_type == '2' && !this.new_field.capacity_uom) {
      this.new_field.ferror.uom = true;
    }
    if (this.new_field.input_type == '3' && this.new_field.list_options.length === 0) {
      this.new_field.ferror.list_options = true;
    }

    // const field_names = this.new_checklist.fields.map(x => x.field_name.toLowerCase());
    // if (field_names.includes(this.new_field.field_description.toLocaleLowerCase())) {
    //   this.new_field.ferror.dup_name = true;
    //   this.alertService.error('Checklist contains duplicate field names');
    // }

    const eKeys = Object.keys(this.new_field.ferror);
    const isValid = eKeys.every((ek) => this.new_field.ferror[ek] == false);
    return isValid;
  }

  onReferenceFilesUpload(files) {
    this.new_field.reference_files = [];
    files.forEach(x => {
      if (x?.status && x.status == 'delete') {
        this.businessService.deleteEWDocument({ sol_mnt_chk_field_id: this.new_field?.id, document_id: x.id }).subscribe();
      }
      this.new_field.reference_files.push({
        id: x?.id, document: x?.file_data || x?.document, file_name: x?.file_name,
        document_size: x.document_size, size: x.size, status: x?.status
      });
    });
  }

  saveChecklist() {
    // if (this.is_my_win_user) {
    //   this.new_checklist.oem_organization = this.solution.solution_org;
    //   if (!this.new_checklist.oem_organization) {
    //     this.alertService.error('Please select an OEM organization to add checklist');
    //     return;
    //   }
    // }
    const is_valid = this.validateChecklist();
    if (!is_valid) {
      this.alertService.error('Please fill all required fields');
      return;
    }

    if (this.solution_id) {
      this.new_checklist.solution = this.solution_id;
    }
    if (this.installed_equipment_id) {
      this.new_checklist.installed_equipment_id = this.installed_equipment_id;
    }

    // if (!(this.solution_id || this.installed_equipment_id)) {
    //   this.new_checklist.checklist_fields = this.new_checklist.fields;
    //   this.new_checklist.checklist_type_name = this.checklist_types.find(x => x.id == this.new_checklist.checklist_type)?.name;
    //   // this.afterSaveChecklist(this.view_checklist_index, this.new_checklist);
    //   this.onCloseChecklistDetails();
    //   return;
    // }
    
    let updateChecklist$: Observable<any>;
    let addChecklist$: Observable<any>;
    if (this.type == 'mnt') {
      addChecklist$ = this.oemService.addSolutionMntChecklist(this.new_checklist);
      updateChecklist$ = this.oemService.updateSolutionMntChecklist(this.new_checklist);
    }
    if (this.type == 'health') {
      addChecklist$ = this.oemService.addSolutionHealthChecklist(this.new_checklist);
      updateChecklist$ = this.oemService.updateSolutionHealthChecklist(this.new_checklist);
    }

    if (this.type == 'install_mnt') {
      addChecklist$ = this.oemService.addInstallationMntChecklist(this.new_checklist);
      updateChecklist$ = this.oemService.updateInstallationMntChecklist(this.new_checklist);
    }
    if (this.type == 'install_health') {
      addChecklist$ = this.oemService.addInstallationHealthChecklist(this.new_checklist);
      updateChecklist$ = this.oemService.updateInstallationHealthChecklist(this.new_checklist);
    }

    if (this.new_checklist.id) {
      updateChecklist$.subscribe((resp) => {
        this.alertService.success(`Checklist "${this.new_checklist.name}" saved`, true);
        // this.afterSaveChecklist(this.view_checklist_index, resp);
        this.onCloseChecklistDetails();
      }, (err) => this.alertService.error('failed to update checklist'));
    } else {
      addChecklist$.subscribe((resp) => {
        this.alertService.success(`Checklist "${this.new_checklist.name}" created`, true);
        // this.afterSaveChecklist(this.view_checklist_index, resp, true);
        this.onCloseChecklistDetails(true);
      }, (err) => this.alertService.error('failed to create checklist'));
    }
  }

  // afterSaveChecklist(save_index, checklist, is_created = false) {
  //   this.modalHideContent.action = 'save';
  //   this.modalHideContent.save_index = save_index;
  //   this.modalHideContent.checklist = checklist;
  //   this.modalHideContent.is_created = is_created;
  // }

  // afterDeleteChecklist(delete_index) {
  //   this.modalHideContent.action = 'delete';
  //   this.modalHideContent.delete_index = delete_index;
  // }

  onRemoveCheckList(checklist, delete_index) {
    this.confirmDialog.showConfirm(`Are you sure you want to delete checklist "${checklist.name}" ?`)
      .pipe(first()).subscribe((status) => {
        if (status) {
          let deleteChecklist$: Observable<any>;
          if (this.type == 'mnt') {
            deleteChecklist$ = this.oemService.deleteSolutionMntChecklist(checklist.id);
          }
          if (this.type == 'health') {
            deleteChecklist$ = this.oemService.deleteSolutionHealthChecklist(checklist.id);
          }
          if (this.type == 'install_mnt') {
            deleteChecklist$ = this.oemService.deleteInstallationMntChecklist(checklist.id);
          }
          if (this.type == 'install_health') {
            deleteChecklist$ = this.oemService.deleteInstallationHealthChecklist(checklist.id);
          }

          if (checklist?.id && (this.solution_id || this.installed_equipment_id)) {
            deleteChecklist$.subscribe((resp) => {
              this.alertService.success('checklist deleted successfully', true);
              // this.afterDeleteChecklist(delete_index);
              this.onCloseChecklistDetails();
            }, (err) => this.alertService.error('failed to delete checklist'));
          } else {
            // this.afterDeleteChecklist(delete_index);
          }
        }
      });
  }

}
