export class User {
    username: number;
    role: string;
    refresh_token: string;
    access_token: string;
    message: string;
    first_name: string;
    last_name: string;
    logo: "";
    user_logo: string;
    company_logo: string;
    user_logo_blob: string;
    company_logo_blob: string;
    user_management_access: boolean;

}
export interface Permission {
    module: number;
    module_name: string;
    feature: number;
    feature_name: string;
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    approve: boolean;
    fill: boolean;
    user_type: string;
}

export enum Module {
    MySolutions = "mySolutions",
    myOpportunities = "myOpportunities",
    myProjects = "myProjects",
    myInstallations = "myInstallations",
    myLearning = "myLearning",
    myPartners = "myPartners"
}

export enum Feature {
    SellableSolutions = "Sellable solutions",
    NonSellableSolutions = "Non-sellable solutions",
    DraftSolutions = "Draft Solutions",
    DocumentUpload = "Document upload",
    UseCases = "Use cases",
    Opportunities = "Opportunities",
    FMProject = "Project",
    ProjectCustomerInformation = "Project - Customer information",
    DynamicDataQualifiersFiltersROIRFQ = "Dynamic data - Qualifiers, Filters, ROI. RFQ",
    DynamicDataTimelineStatus = "Dynamic data - Timeline, Status",
    DynamicDataChecklistSpareList = "Dynamic data - Checklist, Spare list",
    SolutionLayout = "Solution layout",
    InstallationsCustomerInfo = "Installations - Customer and installation information",
    InstallationLayout = "Installation layout",
    Equipment = "Equipment",
    DocumentUploadFMPart = "Document upload - FM part",
    ChecklistFillingRatingSupportRequest = "Checklist - Filling, Rating, Support Request",
    SparePartsRequest = "Spare parts - Request",
    OEMAssignment = "OEM Assignment",
    ProcessLines = "Process Lines",
    PartnerInformation = 'Partner information - Name, Location',
    PartnerAssignment = "Partner assignment - Country, solutions, roles",
    ContactsAssignment = "Partner contacts for myWin platform"
}
export interface SolutionModel {
    id?: number;
    model: string;
    capacity: string;
    capacity_uom: number;
    capacity_uom_name: string;
    selected_uom: any;
    f_error: any;
  }