<!-- <div class="modal-header">
    <h4 class="modal-title pull-left">{{ type }} Checklist</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>checklist-form works!</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div> -->

<div class="row no-gutters page-head sticky-top bg-white">
    <div class="col col-md-8">
        <div class="head-title">
            <img src="/assets/icons/back_arrow.svg" alt="back arrow" (click)="onCloseChecklistDetails()"
                class="hand mr-3">
            <span class="text-nowrap" style="display: inherit !important;font-size: 20px !important;">
                <span *ngIf="!new_checklist?.id">Create {{checklist_category_name}} Checklist</span>
                <span *ngIf="new_checklist?.id && is_view">View {{checklist_category_name}} Checklist</span>
                <span *ngIf="new_checklist?.id && !is_view">Edit {{checklist_category_name}} Checklist</span>

            </span>
        </div>
    </div>

    <div class="col col-md-4 text-right" *ngIf="is_view">
        <ng-container *ngIf="!mobile_view">
            <button *ngIf="dynamic_data_perm?.edit || dynamic_data_perm?.delete" class="btn btn-outline-secondary"
                data-toggle="dropdown" id="editChecklistOptions">
                <span class="fa fa-ellipsis-v mx-1"></span>
            </button>
            <div class="dropdown-menu ew-more-options-box" aria-labelledby="editChecklistOptions">
                <a *ngIf="dynamic_data_perm?.edit" (click)="onEditChecklist()" class="dropdown-item"><span
                        class="fa fa-edit mr-2"></span>
                    Edit Checklist
                </a>
                <a *ngIf="dynamic_data_perm?.delete"
                    (click)="onRemoveCheckList(this.new_checklist, view_checklist_index)" class="dropdown-item"><span
                        class="fa fa-trash mr-2 text-danger"></span>
                    Delete Checklist
                </a>
            </div>
        </ng-container>
    </div>
    <div class="col col-md-4 text-right" *ngIf="!is_view">
        <button class="btn btn-success mr-2 w-102" style="white-space: nowrap;" (click)="saveChecklist()">
            <span *ngIf="!new_checklist?.id">Save & Link</span>
            <span *ngIf="new_checklist?.id">Save</span>
        </button>
    </div>
</div>

<div class="container-fluid overflow-hidden layout-padding">
    <div class="row">
        <div class="col-md-6">
            <div class="row no-gutter">
                <div class="col-md-7 py-1">
                    <label>Name <span class="text-danger-ew">*</span></label>
                    <input type="text" class="form-control" [(ngModel)]="new_checklist.name" [readonly]="is_view">
                    <div *ngIf="new_checklist.ferror.name" class="text-danger fs-085">
                        Checklist name required
                    </div>
                    <div *ngIf="new_checklist.ferror.dup_name" class="text-danger fs-085">
                        Checklist name already exist
                    </div>
                </div>
                <div class="col-md-5 py-1">
                    <label>Type <span class="text-danger-ew">*</span></label>
                    <app-ew-select [options]="checklist_types" [(ngModel)]="new_checklist.checklist_type"
                        optionValue="id" optionText="name" [read_only]="is_view">
                    </app-ew-select>
                    <div *ngIf="new_checklist.ferror.checklist_type" class="text-danger fs-085">
                        Please select a checklist type
                    </div>
                </div>
            </div>
            <div class="py-1">
                <label>Description <span class="text-danger-ew">*</span></label>
                <textarea class="form-control" rows="5" [(ngModel)]="new_checklist.description"
                    [readonly]="is_view"></textarea>
                <div *ngIf="new_checklist.ferror.description" class="text-danger fs-085">
                    Checklist description required
                </div>
            </div>
            <div class="mt-2 mb-2 d-flex align-items-center">
                <div class="sub-title2 flex-grow-1">Task List ({{new_checklist.fields.length}})</div>
                <ng-container *ngIf="!is_view">
                    <div *ngIf="!(show_new_task_form && new_field?.id)" class="position-relative">
                        <button *ngIf="!mobile_view" class="btn btn-sm1 btn-outline-primary"
                            [class.bg-highlight]="show_new_task_form && !new_field?.id"
                            (click)="onAddNewChecklistTask()">Add a
                            Task
                            <span class="fa fa-angle-right ml-2 mr-1 fa-lg"></span>
                        </button>
                        <span *ngIf="show_new_task_form && !new_field?.id"
                            class="fa fa-caret-right fa-2x ew-text-highlight"
                            style="position: absolute;right: -5px;top: 1px;"></span>
                    </div>
                </ng-container>
            </div>
            <div class="form-group">
                <ul class="list-group round-8"
                    [style.border-bottom]="new_field?.field_name && mobile_view ? '3px solid' : '0'">
                    <li class="list-group-item px-3 py-2 hand"
                        [class.bg-highlight]="new_field?.field_name == field?.field_name"
                        *ngFor="let field of new_checklist.fields; let i = index;" (click)="viewChecklistField(field)">
                        <span class="">{{field.field_description}}</span>
                        <span *ngIf="!is_view" class="fa fa-trash float-right pt-1 hand"
                            (click)="removeField(field, i, $event)"></span>
                        <span *ngIf="new_field?.field_name == field?.field_name && !mobile_view"
                            class="fa fa-caret-right fa-2x ew-text-highlight"
                            style="position: absolute;right: -10px;top: 6px;"></span>
                    </li>
                </ul>
                <span *ngIf="new_field?.field_name && mobile_view" class="fa fa-caret-down fa-3x ew-text-highlight"
                    style="position: absolute; bottom:-10px; left: 33px;"></span>
            </div>
        </div>
        <div *ngIf="show_new_task_form" class="col-md-6">
            <div class="my-2 sub-title2">
                <span *ngIf="new_checklist?.id && !is_view">Edit a Task</span>
                <span *ngIf="new_checklist?.id && is_view">Task</span>
            </div>
            <div class="form-group">
                <label>Task <span class="text-danger-ew">*</span></label>
                <textarea class="form-control" rows="2" [(ngModel)]="new_field.field_description"
                    [readonly]="is_view"></textarea>
                <div *ngIf="new_field.ferror.field_description" class="text-danger fs-085">
                    Field name/description required
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 form-group">
                    <label>Input Type <span class="text-danger-ew">*</span></label>
                    <app-ew-select label="" [options]="input_types" [(ngModel)]="new_field.input_type" optionValue="id"
                        optionText="name" [read_only]="is_view">
                    </app-ew-select>
                    <div *ngIf="new_field.ferror.input_type" class="text-danger fs-085">
                        Input type required
                    </div>
                </div>
                <div class="col-md-7 form-group">
                    <div *ngIf="new_field.input_type == '2'">
                        <label>UOM <span *ngIf="!is_view" class="text-danger">*</span></label>
                        <app-ew-select label="" [options]="capacity_uoms" [(ngModel)]="new_field.capacity_uom"
                            optionValue="id" optionText="uom_name" [read_only]="is_view">
                        </app-ew-select>
                        <div *ngIf="new_field.ferror.uom" class="text-danger fs-085">
                            Unit of measurement required
                        </div>
                    </div>
                    <div *ngIf="new_field.input_type == '3'">
                        <label>Dropdown Options <span *ngIf="!is_view" class="text-danger">*</span></label>
                        <textarea rows="2" class="form-control"
                            placeholder="Separate each option with a comma &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (eg: 100, 120, 140, 160)"
                            [class.input-error]="new_field.ferror.list_options" [(ngModel)]="new_field.list_options"
                            [readonly]="is_view"></textarea>
                        <div *ngIf="new_field.ferror.list_options" class="text-danger fs-085">
                            Dropdown options required
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Instructions</label>
                <textarea class="form-control" rows="2" [(ngModel)]="new_field.oem_instructions"
                    [readonly]="is_view"></textarea>
            </div>

            <div class="form-group">
                <label>Reference Files</label>
                <app-ew-file-upload2 [in_files]="new_field.reference_files" (upload)="onReferenceFilesUpload($event)"
                    [read_only]="is_view">
                </app-ew-file-upload2>
            </div>
            <div class="form-group">
                <button *ngIf="!is_view" class="btn btn-sm btn-success mr-2" (click)="onSaveNewChecklistTask()">
                    Save Task
                </button>
                <button class="btn btn-sm btn-outline-secondary mr-2" (click)="onCancelChecklistTask()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>